.queryBuilder {
  padding-top: 15px;

  .ruleGroup {
    padding: 10px;
    margin-left: 30px;
    border: 1px solid lightgrey;

    .ruleGroup-header {
      .ruleGroup-combinators {
        @extend .form-control;
        width: unset;
        display: inline;
        margin: 5px;
      }

      .ruleGroup-addRule {
        @extend .btn;
        @extend .btn-secondary;
        @extend .bg-gradient-theme-left;
        @extend .border-0;
        margin: 5px;
        margin-bottom: 8px;
      }

      .ruleGroup-addGroup {
        @extend .btn;
        @extend .btn-secondary;
        @extend .bg-gradient-theme-left;
        @extend .border-0;
        margin: 5px;
        margin-bottom: 8px;
      }

      .ruleGroup-remove {
        @extend .btn;
        @extend .btn-danger;
        margin-bottom: 4px;
      }
    }

    .rule {
      margin-left: 25px;

      .rule-fields {
        @extend .form-control;
        width: unset;
        display: inline;
        margin: 5px;
      }

      .rule-operators {
        @extend .form-control;
        width: unset;
        display: inline;
        margin: 5px;
      }

      .rule-value {
        @extend .form-control;
        width: unset;
        display: inline;
        margin: 5px;
      }

      .rule-remove {
        @extend .btn;
        @extend .btn-danger;
        margin-bottom: 4px;
      }
    }
  }

  > .ruleGroup {
    padding-left: 0px;
    margin-left: 0px;
    border: none;
  }
}

.disabled .ruleGroup-combinators {
  pointer-events: none;
  background-color: #f4f4f4 !important;
}

.disabled .ruleGroup-addRule {
  display: none !important;
}

.disabled .ruleGroup-addGroup {
  display: none !important;
}

.disabled .ruleGroup-remove {
  display: none !important;
}

.disabled .rule-fields {
  pointer-events: none;
  background-color: #f4f4f4 !important;
}

.disabled .rule-operators {
  pointer-events: none;
  background-color: #f4f4f4 !important;
}

.disabled .rule-value {
  pointer-events: none;
  background-color: #f4f4f4 !important;
}

.disabled .rule-remove {
  display: none !important;
}
