.icon-input-group {
  height: 100px;
  align-items: center !important;
  background: white;
  border: 2px solid #e4e4e4;
}

.icon-input-group.top {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px;
}
.icon-input-group.bottom {
  border-radius: 0px 0px 10px 10px;
}

.icon-input-icon {
  background-color: transparent !important;
  border: 0px !important;
  padding: 30px !important;
  opacity: 1;
}

.icon-text-input {
  background-color: transparent !important;
  border: 0px !important;
  height: auto;
}

.icon-input-icon .icon {
  width: 32px;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.input {
}
