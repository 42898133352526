.dashboard-card {
  width: 270px;
  height: 320px;
  box-shadow: 0px 3px 15px #4b4b4b15;
  border-radius: 10px;
}

.dashboard-card .head {
  height: 120px;
  padding: 20px;

  box-shadow: 0px 3px 15px #4b4b4b15;
  border-radius: 10px;
}

.dashboard-card-image {
  padding: 12px;
}

.dasboard-card-title {
  padding: 8px 0px;
}

.dasboard-card-title > span:nth-child(1) {
  font-size: 25px;
}
.dasboard-card-title > span:nth-child(2) {
  font-size: 18px;
}

.dashboard-card-body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 200px;
  padding: 24px;
}

.dashboard-bullet {
  list-style: none;
}

.dashboard-bullet li::before {
  content: '\2022';
  display: inline-block;
  width: 1em;
  height: 36px;
  font-size: 1.25rem;
  font-weight: 1000;
}
