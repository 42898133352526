#signIn {
  form {
    hr {
      margin-top: 30px;
    }
    .text-forgot-password,
    .text-sign-up {
      margin-top: 10px;
    }
  }
}
