.btn-previous {
  margin-top: -28px;
  margin-bottom: 2px;
  margin-left: -20px;
  cursor: pointer !important;
}

.btn-previous:hover {
  border: 1px solid $primary;
}

.btn-next {
  margin-top: -28px;
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right: 4px;
  cursor: pointer !important;
}

.btn-next:hover {
  border: 1px solid $primary;
}
