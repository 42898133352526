.mark-required {
  color: $red;
}

.form-control {
  min-height: calc(1.5em + 0.75rem + 12px);
  border: 1px solid #e4e4e4;
  background-color: transparent;
}

.textbox-error,
.select-error {
  border: 1px solid $red;
}

.form-error {
  color: $red;
  font-size: 14px;
  margin-top: 5px;
}

.field-description {
  font-size: 12px;
  font-style: italic;
}

.field-group {
  display: flex;
  align-items: center !important;
  background: #f4f4f4;
  border-radius: 10px;
  padding: 0px 12px;
}

.c-text-label {
  font-family: MulishBold;
  font-size: 15px;
}

// .c-text-input {
//   background-color: transparent;
//   border: 1px solid #e4e4e4;
//   border-radius: 6px;
// }

// .c-text-input::placeholder {
//   font-style: italic;
//   font-size: 14px;
//   color: rgba(36, 41, 58, 0.3);
// }

.cbutton-base {
  padding: 12px 24px;
  border-radius: 50px;
  color: white;
  font-family: MulishBold;
  font-size: 22px;
}

.cbutton-base.disabled,
.cbutton-base:disabled,
.cbutton-base:hover {
  color: white;
}

.c-button-black {
  color: #fff;
  background: #24293a -webkit-gradient(linear, left top, left bottom, from(#454958), to(#24293a)) repeat-x;
  background: #24293a linear-gradient(180deg, #454958, #24293a) repeat-x;
  border-color: #24293a;
}
.c-button-black:hover,
.c-button-black:active {
  color: #fff;
  background: #151822 -webkit-gradient(linear, left top, left bottom, from(#383b43), to(#151822)) repeat-x;
  background: #151822 linear-gradient(180deg, #383b43, #151822) repeat-x;
  border-color: #10131b;
}

.order-add-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  border: 0;
  width: 128px;
  padding: 12px;
}

.order-add-item.price {
  background-color: #f2f2f2;
  margin-right: 8px;
}

.order-add-item.qty {
  background-color: #f7f7f7;
  margin-right: 4px;
}

.order-add-item .promotion {
  background-color: #f2f3fa;
  border-radius: 5px;
  width: auto;
}

.icon-button {
  background: transparent !important;
  border: 0;
  padding: 4px;
}

.circle {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.page-card {
  padding: 24px;
  background-color: white;
  box-shadow: 0px 3px 15px #4b4b4b15;
  border-radius: 10px;

  .category {
    .label {
      font-family: MulishBold;
      font-size: 15px;
      opacity: 0.7;
    }
    .value {
      font-family: MulishSemiBold;
      font-size: 18px;
    }
  }
}
