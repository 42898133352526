@import '../variables';

.rc-pagination {
  display: flex;
  font-size: 18px;
}

.rc-pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 48px;
  height: 48px;
  margin-right: 8px;
  font-family: MulishBold;
  color: rgba(192, 191, 191, 0.96);
  border: 0;
  border-radius: 4px;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover {
  background-color: $primary;
  color: white;
}

.rc-pagination-item:focus a,
.rc-pagination-item:hover a {
  color: white;
}

.rc-pagination-item-active {
  background-color: $primary;
  color: white;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 48px;
  height: 48px;
  margin-right: 8px;
  border: 0;
  border-radius: 4px;
}

.rc-pagination-prev button:after {
  content: '';
}
.rc-pagination-next button:after {
  content: '';
}

.rc-pagination-prev:hover button,
.rc-pagination-next:hover button {
  border-color: none;
}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
  background-color: $warning;
  border-radius: 4px;
  border: 0;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0.6;
}

.rc-pagination-prev:focus .rc-pagination-item-link,
.rc-pagination-next:focus .rc-pagination-item-link,
.rc-pagination-prev:hover .rc-pagination-item-link,
.rc-pagination-next:hover .rc-pagination-item-link {
  border-color: transparent;
  opacity: 1;
}

.rc-pagination-disabled,
.rc-pagination-disabled:hover,
.rc-pagination-disabled:focus {
  cursor: not-allowed;
  background: $disabled;
}

.rc-pagination-disabled .rc-pagination-item-link,
.rc-pagination-disabled:hover .rc-pagination-item-link,
.rc-pagination-disabled:focus .rc-pagination-item-link {
  background-color: $disabled;
}
