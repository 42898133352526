.cardImage {
  height: 300px;
  border-radius: 10px 10px 0px 0px;
  object-fit: contain;
}

.cardBody {
  box-shadow: 0px 3px 15px #67676715;
  border-radius: 0px 0px 10px 10px;
}

.cardProductHead {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  font-family: MulishBold;
  font-weight: bolder;
  font-size: 22px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 72px !important; /* font size * line height * line to show. Fallback for non-webkit */
}

.cardProductSub {
  font-family: MulishBold;
  size: 17px;
}

.cardProductDescription {
  font-family: MulishSemiBold;
  font-size: 17px;
  opacity: 0.4;
  width: 100%;
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow: ellipsis;
}
