.nav-header {
  justify-content: space-between !important;
}

.nav-header .nav-tab {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
}

.nav-header .nav-tab a {
  height: 100%;
}
