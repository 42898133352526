.status-warning {
  background-color: $warning;
}

.status-secondary {
  background-color: $secondary;
}

.status-primary {
  background-color: $primary;
}

.status-success {
  background-color: $success;
}

.status-danger {
  background-color: $danger;
}

.status-info {
  background-color: $info;
}

.status-purple {
  background-color: $purple;
}

.status-green {
  background-color: $green;
}

.status-indigo {
  background-color: $indigo;
}

.status-teal {
  background-color: $teal;
}

.status-blue {
  background-color: $blue;
}
