// page
.cr-page {
  min-height: 100vh;

  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    display: flex;
    align-items: flex-end;
    align-self: center;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }

    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: theme-color('light');
  }

  .row {

    // stylelint-disable-next-line
    .col,
    >[class^='col-'] {
      margin-bottom: 1rem;
    }
  }
}

.cr-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactTable.custom-highlight {
  .rt-tr-group {
    border-radius: 0.2rem;
    padding: 5px;

    &:hover {
      background: linear-gradient(to left, $white, $yellow);
      cursor: pointer;
    }
  }
}

.dashboard-page {
  padding: 24px !important;
}

.ReactTable .rt-tbody {
  overflow: hidden;
}

@function get-vw($target) {
  $vw-context: (
    1000*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

$window-width-xs: get-vw(300px
);
$window-width-s: get-vw(250px);
$window-width-m: get-vw(200px);
$window-width-l: get-vw(150px);

@media screen and (max-width: 1000px) {
  .ReactTable {
    width: calc(100vw - $window-width-xs);
  }

  .table-overflow {
    width: calc(100vw - $window-width-xs);
  }
}

@media screen and (min-width: 1001px) {
  .ReactTable {
    width: calc(100vw - $window-width-s);
  }

  .table-overflow {
    width: calc(100vw - $window-width-s);
  }
}

@media screen and (min-width: 1301px) {
  .ReactTable {
    width: calc(100vw - $window-width-m);
  }

  .table-overflow {
    width: calc(100vw - $window-width-m);
  }
}

@media screen and (min-width: 1701px) {
  .ReactTable {
    width: calc(100vw - $window-width-l);
  }

  .table-overflow {
    width: calc(100vw - $window-width-l);
  }
}