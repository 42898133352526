.c-table {
  background-color: white;
  border: 0;
  border-radius: 5px;
  padding: 24px;
}

.pagination {
  margin-bottom: unset;
}

.select-page-size {
  margin-left: auto;
  margin-right: 32px;
  border: 0;
  border-radius: 4px;
  padding: 12px 24px 12px 12px;
  background-color: rgba(241, 163, 56, 0.1);
}
