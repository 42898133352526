.dePyzG {
  display: flex;
  flex-direction: column;
}

.jwfbbd {
  font-size: 14px;
  padding: 10px 20px;
  height: 40px;
  border: 1px solid #cacaca96;
  border-radius: 5px;
  color: #000;
}

.zNoeh {
  margin: 10px 0 0;
  background-color: #fff;
  //   box-shadow: rgb(0 0 0 / 10%) 0 0 0 0, rgb(0 0 0 / 10%) 0px 4px 11px;
  border-radius: 5px;
}

.zNoeh > ul > li:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.zNoeh > ul > li:not(:first-child) {
  border-top: 0;
}

.zNoeh > ul > li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.zNoeh > ul > li {
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #cacaca96;
  height: 40px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.react-search-box-dropdown ul {
  padding-left: 5px;
}

.select-search-box {
  padding: 0;
  min-height: auto;
}
.css-2b097c-container {
  padding: 0;
}
.rt-th .css-2b097c-container {
  border: none !important;
}

.css-yk16xz-control {
  height: 100%;
  border-width: 0px !important;
}
.css-1pahdxg-control {
  height: 100%;
}

.css-14jk2my-container {
  padding: 0;
}
.css-1fhf3k1-control {
  height: 100%;
}

.rt-th .css-1fhf3k1-control {
  height: 100%;
  border-width: 0px !important;
}

.rt-th .css-1okebmr-indicatorSeparator {
  margin-bottom: 16px !important;
  margin-top: 2px !important;
}

.rt-th .css-tlfecz-indicatorContainer,
.rt-th .css-1gtu0rj-indicatorContainer {
  margin-top: -8px !important;
}

.rt-th .css-1wa3eu0-placeholder,
.rt-th .css-b8ldur-Input {
  margin-top: -2px !important;
}

.rt-th .css-1vz5lvl-control {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.rt-th .css-1uccc91-singleValue {
  margin-top: -4px !important;
}
