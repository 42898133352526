.dashboard-bullet li:nth-child(1)::before {
  color: $list1;
}
.dashboard-bullet li:nth-child(2)::before {
  color: $list2;
}
.dashboard-bullet li:nth-child(3)::before {
  color: $list3;
}
.dashboard-bullet li:nth-child(4)::before {
  color: $list4;
}
.dashboard-bullet li:nth-child(5)::before {
  color: $list5;
}
.dashboard-bullet li:nth-child(6)::before {
  color: $list6;
}
.dashboard-bullet li:nth-child(7)::before {
  color: $list7;
}
