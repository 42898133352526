@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Mulish, Poppins, Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: $font-weight-normal;
  color: #24293a;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.card-body {
  overflow-x: auto;
}

.card {
  border: none;
  border-radius: 10px;
}

@font-face {
  font-family: 'MulishBold';
  font-weight: bold;
  src: url('../assets/fonts/Mulish-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-weight: normal;
  src: url('../assets/fonts/Mulish-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MulishSemiBold';
  font-weight: normal;
  src: url('../assets/fonts/Mulish-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'MulishSemiBoldItalic';
  font-weight: normal;
  src: url('../assets/fonts/Mulish-SemiBoldItalic.ttf') format('truetype');
}

.muli {
  font-family: Mulish;
}

.muli-bold {
  font-family: MulishBold;
}

.muli-semi {
  font-family: MulishSemiBold;
}

.muli-semi-italic {
  font-family: MulishSemiBoldItalic;
}

.poppins {
  font-family: Poppins;
}

.border-underline {
  border-bottom: 1px solid rgba(230, 230, 230, 0.8);
}

.page-title {
  font-family: MulishBold;
  font-size: 35px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.select-overflow {
  overflow: scroll;
}

.no-border-hover .Select__control:hover {
  border-color: transparent !important;
}