.product-detail-page {
  .available-qty {
    height: 160px;
    padding: 24px;
    border-radius: 10px;

    background-color: $success;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    font-family: MulishBold;
    color: white;

    .title {
      font-size: 22px;
    }
    .value {
      font-size: 42px;
    }
  }

  .detail-body {
    background: white;
    box-shadow: 0px 3px 15px #4b4b4b15;
    border-radius: 0px 0px 10px 10px;
  }

  .tag-product-type {
    width: 120px;
    padding: 8px 24px;
    border-radius: 24px;
    color: white;
    text-align: center;
    margin: auto;
    background-color: $secondary;
  }
  .tag-product-category {
    width: 120px;
    padding: 8px 24px;
    border-radius: 24px;
    color: white;
    text-align: center;
    margin: auto;
    background-color: $warning;
  }
  .tag-product-brand {
    width: 120px;
    padding: 8px 24px;
    border-radius: 24px;
    color: white;
    text-align: center;
    margin: auto;
    background-color: $primary;
  }
}

.qty-tooltip {
  .tooltip-inner {
    padding: 24px;
    background: white;
    color: $font;
    font-size: 17px;
    max-width: 400px;
    text-align: start;
  }
  .arrow {
    &::before {
      border-bottom-color: white;
    }
  }
}

.product-detail-qty-card {
  width: 190px;
  height: 160px;
  padding: 12px;
  background-color: white;
  box-shadow: 0px 3px 15px #4b4b4b15;
  border-radius: 10px;
  text-align: center;
  margin: 0px auto;

  .image {
    width: 60px;
    margin-bottom: 12px;
  }
  .title {
    font-family: MulishSemiBold;
    font-size: 16px;
    margin-bottom: 8px;
  }
  .value {
    font-family: MulishBold;
    font-size: 22px;
  }
}

.tab-card {
  padding: 10px 10px 10px 24px;
  background-color: white;
  box-shadow: 0px 3px 15px #4b4b4b15;
  border-radius: 10px;
  margin-bottom: 24px;
  font-family: MulishBold;

  .category {
    padding: 16px;
    margin: 0px;

    .title {
      font-size: 17px;
      opacity: 0.5;
      margin-bottom: 12px;
    }
    .value {
      font-size: 25px;
    }
  }

  .table {
    border: 0;
    background-color: #f7f7f7;
    .head {
      font-family: MulishBold;
      font-size: 20px;
      border-bottom: 1px solid #e5e5e5;
    }
    .head > th {
      border-right: 1px solid #e5e5e5;
    }
    .head > th:nth-child(4) {
      border: 0;
    }

    .cell {
      font-family: MulishSemiBold;
      font-size: 20px;
    }
    .cell > td {
      border-right: 1px solid #e5e5e5;
    }
    .cell > td:nth-child(4) {
      border: 0;
    }
  }

  .price-operator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 40px;
    border-radius: 12px;
    background-color: #ebebeb;
    font-size: 19px;
    margin-right: 36px;
  }

  .variant {
    .image {
      width: 156px;
      height: 156px;
      border: 1px solid #e0e0e0;
      border-radius: 20px;
      object-fit: scale-down;
    }

    .variant-color {
      display: flex;
      &::before {
        content: '';
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 24px;
        background-color: var(--bg-color);
      }
    }
  }
}

.product-detail-tabs {
  margin-bottom: 32px;
  .nav-link {
    padding: 16px;
    font-size: 22px;
    opacity: 0.3;
  }
  .active-item-tab {
    .active {
      font-family: MulishBold;
      background-color: transparent;
      border: 0px;
      opacity: 1;
    }
  }
  .active-item-tab::after {
    content: '';
    display: block;
    border-radius: 30px;
    border-bottom: 8px solid $warning;
  }
}
