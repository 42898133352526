.header-nav-item {
  min-width: 168px;
  font-family: MulishSemi;
  font-size: 22px;
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(36, 41, 58, 0.5);

    &.active {
      color: map-get($map: $theme-colors, $key: 'primary');
      background: rgba(255, 255, 255, 0.23);
      opacity: 1;
      // border-bottom: 8px solid map-get($map: $theme-colors, $key: 'primary');
      font-family: MulishBold;
    }
  }
  &.active-item-tab::after {
    content: '';
    display: block;
    border-radius: 30px;
    border-bottom: 8px solid $primary;
    margin-top: -8px;
  }
}

.new-order-nav-item {
  font-size: 20px;
  width: 180px;
  height: 84px;
  margin-bottom: 8px !important;
  color: white;

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    background-color: $order-tab;
    box-shadow: 0px 3px 26px #8e8e8e12;
    border-radius: 10px 0px 0px 10px;
    border: 0;

    &.active {
      // background-color: $order-tab;
      // box-shadow: 0px 3px 26px #8e8e8e12;
      // border-radius: 10px 0px 0px 10px;
      // color: white;
      // border: 0;
    }
    &.disabled {
      background-color: white;
      box-shadow: 0px 3px 10px #67676715;
      border-radius: 10px 0px 0px 10px;
      color: #24293a;
    }
  }
}

.navbar {
  padding: 1rem 2rem;
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.rounded-button {
  border-radius: 10px !important;
}

input {
  background-color: transparent;
}

._loading_overlay_content .css-mz0q36 {
  position: absolute !important;
  top: 40vh !important;
}
