.card-img-left {
  border-radius: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-img-right {
  border-radius: 0;
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}

.card-steps {
  position: fixed;
  top: 100px;
  margin-left: -22px;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 230px;
}

.step-content {
  margin-top: 100px;
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    width: 100%;
  }
}

input[type='file']::file-selector-button {
  background-color: #fff;
  color: #000;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 10px 15px;
  margin-right: 20px;
  transition: 0.5s;
}

input[type='file']::file-selector-button:hover {
  background-color: #eee;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  border-radius: 3px;
}
