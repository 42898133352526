table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 15px;
}

table tr.active {
  background: linear-gradient(to left, #fff, #ffeb3b);
}

.rt-th input,
.rt-th select {
  height: 40px;
}
