.ReactTable .rt-thead.-filters select {
  width: 100%;
}

.pagination {
  .page-item {
    width: 30px;
    text-align: center;

    .page-link {
      cursor: pointer;
    }
  }
}

.basic-message-modal {
  padding: 24px;
  border-radius: 10px !important;
  text-align: center;

  .modal-header {
    justify-content: center;
    .modal-title {
      font-family: MulishBold;
      font-size: 25px;
      justify-content: center;
    }
  }

  .modal-body {
    font-size: 18px;
    min-height: 150px;
    padding: 36px;
  }

  .modal-footer {
    border: 0;
    justify-content: center;
  }
}
