// override bootstrap 4 variables

$blue: #2196f3;
$indigo: #536dfe;
$purple: #9c27b0;
$pink: #ff4081;
$red: #f44336;
$orange: #ff9800;
$yellow: #ffeb3b;
$green: #4caf50;
$teal: #009688;
$cyan: #00bcd4;
$white: #fff;

$primary: #d83e7c;
$secondary: #68b5c1;
$success: #98c04f;
$danger: #e74340;
$warning: #f1a338;
$info: #00c9ff;

$font: #24293a;
$sidebar: #822e83;

$disabled: rgba(192, 191, 191, 0.1);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'warning': $warning,
  'info': $info,
);
// $theme-colors: (
//   "primary": #FADA5E,
//   "secondary": #F9A602,
//   "success": #45b649,
//   "danger": #f85032,
//   "warning": #ffd700,
//   "info": #00c9ff
// );

$list1: $sidebar;
$list2: $secondary;
$list3: $primary;
$list4: $warning;
$list5: $success;
$list6: $danger;
$list7: $font;

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;

// sidebar
$cr-sidebar-width: 224px;

//tab
$order-tab: $list1;
