.cr-widget {
  display: flex;
  flex-direction: row;

  &__icon {
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
  }
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  background: transparent;
  padding: 12px;
}

.order-new-modal .modal-content {
  padding: 48px;
  height: 800px;
  overflow-y: auto;
}

.order-new-modal-header {
  display: block;
  text-align: center;
  position: relative;
  border-bottom: 0;
}
.order-new-modal-header .modal-title {
  font-family: MulishBold;
  font-size: 2rem;
}

.order-new-modal-header .close {
  top: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  margin: 0;
}
