.product-section {
  overflow: auto;
  max-height: 560px;

  .product-image {
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .modal-dialog {
    max-width: 90% !important;
    margin: 1rem auto;
  }
}

.text-group {
  font-family: MulishBold;
  font-size: 15px;
  .label {
    opacity: 0.5;
  }
  .value {
    font-size: 16px;
  }
}

.discount-info {
  padding: 5px 15px;
  background-color: #f2f2f2;
  margin-top: 10px;
  border-radius: 2px;
}
