.search-group {
  width: 460px;
  margin-bottom: auto;
  margin-right: 24px;

  display: flex;
  align-items: center !important;
  background: #f4f4f4;
  border-radius: 10px;
}

.search-input-icon {
  background-color: transparent !important;
  border: 0px !important;
  opacity: 1;
  padding: 18px;
}

.icon {
  width: 24px;
  height: 24px;
}

.search-input {
  border: 0px;
}

.search-input::placeholder {
  font-family: MulishSemiBoldItalic;
}

.search-input:focus {
  background-color: transparent;
}
