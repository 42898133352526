.react-autosuggest__image {
  width: 100%;
  height: auto;
  max-width: 100px;
  margin-right: 24px;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  padding: 10px 20px;
  font-family: MulishBold;
  font-size: 20px;
  background-color: #efefef;
  border-radius: 10px;
  border: 0;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  max-height: 450px !important;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  overflow-y: scroll;

  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  max-height: 500px;
  background: #f9fafc;
  box-shadow: 0px 3px 12px #3c3c3c29;
  border: 1px solid #d9d9d9;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 24px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.name {
  margin-left: 68px;
  line-height: 45px;
}

.highlight {
  color: #ee0000;
  font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}
